import {Redirect} from "react-router-dom";
import React, {lazy} from "react";


import ClientGuard from "../components/guards/ClientGuard";
import AdminGuard from "../components/guards/AdminGuard";
import OfficeGuard from "../components/guards/OfficeGuard";
import AdminNatGuard from "../components/guards/AdminNatGuard";
import ProvinceGuard from "../components/guards/ProvinceGuard";

const authProtectedRoutes2 = [
    //ADMIN
    {path: "/admin/banks", component: lazy(() => import("../pages/Admin/Banks/BanksPage")), guard: AdminGuard},
    {path: "/admin/configs", component: lazy(() => import("../pages/Admin/Configs/ConfigsPage")), guard: AdminGuard},
    {
        path: "/admin/substances",
        component: lazy(() => import("../pages/Admin/Substances/SubstancesPage")),
        guard: AdminGuard
    },
    {
        path: "/admin/postes-frontieres",
        component: lazy(() => import("../pages/Admin/PostesFront/PostesFrontPage")),
        guard: AdminGuard
    },

    //RAPPORT
    // CLIENT BUY
    {
        path: "/rapports/matieres",
        component: lazy(() => import("../pages/reports/Matieres/MatieresPages")),
        guard: AdminGuard
    },


    // CLIENT BUY
    {path: "/client/panier", component: lazy(() => import("../pages/Facturation/FacturationPage")), guard: ClientGuard},

    {
        path: "/client/souscriptions",
        component: lazy(() => import("../pages/Facturation/Souscriptions/SouscriptionsPage")),
        guard: ClientGuard
    },

    {
        path: "/client/documents",
        component: lazy(() => import("../pages/Facturation/UnlockDocPage/UnlockDocPage")),
        guard: ClientGuard
    },
    //OFFICE SALE

    {
        path: "/office/panier",
        component: lazy(() => import("../pages/Facturation/FacturationPage")),
        guard: OfficeGuard
    },

    {
        path: "/office/souscriptions",
        component: lazy(() => import("../pages/Facturation/Souscriptions/SouscriptionsPage")),
        guard: OfficeGuard
    },

    {
        path: "/office/documents",
        component: lazy(() => import("../pages/Facturation/UnlockDocPage/UnlockDocPage")),
        guard: OfficeGuard
    },

// BANK SIMULATE
    {path: "/bank/check_pay", component: lazy(() => import("../pages/BanksCheck/BankPayCheckPage")), guard: AdminGuard},

    // PREDEDOUANEMENT
    {
        path: "/pre-dedouanement/demandes",
        component: lazy(() => import("../pages/prededouanement/Commencement/CommencementPage")),
        guard: AdminGuard
    },

    {
        path: "/pre-dedouanement/pre-alerte",
        component: lazy(() => import("../pages/prededouanement/pre-alert/PreAlertPage")),
        guard: AdminGuard
    },
    // DEDOUANEMENT

    {
        path: "/dedouanement/douane",
        component: lazy(() => import("../pages/dedouanement/douane/DouanePage")),
        guard: AdminGuard
    },
    {
        path: "/dedouanement/liste-gouv",
        component: lazy(() => import("../pages/dedouanement/liste-gouv/ListeGouvPage")),
        guard: AdminGuard
    },

    // SERVICE
    {
        path: "/services/occ",
        component: lazy(() => import("../pages/services-etats/occ/ServiceOCCPage")),
        guard: AdminGuard
    },

    {
        path: "/services/dgda",
        component: lazy(() => import("../pages/services-etats/dgda/ServiceDGDAPage")),
        guard: AdminGuard
    },
    {
        path: "/services/ceec",
        component: lazy(() => import("../pages/services-etats/ceec/ServiceCEECPage")),
        guard: AdminGuard
    },
    {
        path: "/services/cgea",
        component: lazy(() => import("../pages/services-etats/cgea/ServiceCGEAPage")),
        guard: AdminGuard
    },
    {
        path: "/services/division-mine",
        component: lazy(() => import("../pages/services-etats/division-mine/ServiceDivisionMinePage")),
        guard: AdminGuard
    },
    {
        path: "/services/cce-exterieur",
        component: lazy(() => import("../pages/services-etats/cce-exterieur/ServiceDivisionCommerceExterieurPage")),
        guard: AdminGuard
    },
    {
        path: "/services/pnhf",
        component: lazy(() => import("../pages/services-etats/pnhf/ServicePNHFPage")),
        guard: AdminGuard
    },
    {
        path: "/services/lmc",
        component: lazy(() => import("../pages/services-etats/lmc/ServiceLMCPage")),
        guard: AdminGuard
    },
    {
        path: "/services/ogefrem",
        component: lazy(() => import("../pages/services-etats/ogefrem/ServiceOGEFREMPage")),
        guard: AdminGuard
    },

    // DECLARATION
    {
        path: "/sortie/declaration",
        component: lazy(() => import("../pages/Mouvements/Declaration/DeclarationPage")),
        guard: OfficeGuard
    },

    {
        path: "/sortie/tracking",
        component: lazy(() => import("../pages/Mouvements/Tracking/TrackingPage")),
        guard: OfficeGuard
    },

    // MINING

    {
        path: "/mining/unites-transformation",
        component: lazy(() => import("../pages/Mining/UniteTrans/UniteTransPage")),
        guard: AdminGuard
    },

    {path: "/mining/bureaux", component: lazy(() => import("../pages/Mining/Bureaux/BureauxPage")), guard: AdminGuard},

    {
        path: "/mining/circuits",
        component: lazy(() => import("../pages/Mining/Circuits/CircuitsPage")),
        guard: AdminGuard
    },

    {
        path: "/mining/checkpoints",
        component: lazy(() => import("../pages/Mining/CheckPoints/CheckPointsPage")),
        guard: AdminGuard
    },

    {
        path: "/mining/transitaires",
        component: lazy(() => import("../pages/Mining/Transitaires/TransitairesPage")),
        guard: AdminGuard
    },
    {
        path: "/mining/comptoirs-achat",
        component: lazy(() => import("../pages/Mining/ComptoirsAchat/ComptoirsAchatPage")),
        guard: AdminGuard
    },


    {
        path: "/mining/transporteurs",
        component: lazy(() => import("../pages/Mining/Transporteurs/TransporteursPage")),
        guard: AdminGuard
    },
    {
        path: "/mining/vehicules",
        component: lazy(() => import("../pages/Admin/Vehicules/VehiculesPage")),
        guard: AdminGuard
    },

    {
        path: "/mining/exploitants",
        component: lazy(() => import("../pages/Mining/Exploitants/ExploitantsPage")),
        guard: AdminGuard
    },

    {
        path: "/mining/concessions",
        component: lazy(() => import("../pages/Mining/Clients/ClientsPage")),
        guard: AdminGuard
    },
// USERS

    {
        path: "/users/admin",
        component: lazy(() => import("../pages/users/AdminUsers/AdminUsersPage")),
        guard: AdminGuard
    },

    {
        path: "/users/mining",
        component: lazy(() => import("../pages/users/ClientUsers/ClientUsersPage")),
        guard: AdminGuard
    },

    {
        path: "/users/etat",
        component: lazy(() => import("../pages/users/OfficeUsers/OfficeUsersPage")),
        guard: AdminGuard
    },

    {
        path: "/users/checkpoint",
        component: lazy(() => import("../pages/users/CheckpointUsers/CheckpointUsersPage")),
        guard: AdminGuard
    },

    {
        path: "/users/national",
        component: lazy(() => import("../pages/users/NationalUsers/NationalUsersPage")),
        guard: AdminGuard
    },

    {
        path: "/users/province",
        component: lazy(() => import("../pages/users/ProvincialUsers/ProvincialUsersPage")),
        guard: AdminGuard
    },


    {
        path: "/tests",
        component: lazy(() => import("../pages/TestPage")),
    },

    // STOCK
    {
        path: "/g-stock/warehouse",
        component: lazy(() => import("../pages/gstock/warehouse/WarehousePage")),
        guard: AdminNatGuard
    },
    {
        path: "/g-stock/province",
        component: lazy(() => import("../pages/gstock/provincial/ProvincialStockPage")),
        guard: ProvinceGuard
    },
    {
        path: "/g-stock/bureau",
        component: lazy(() => import("../pages/gstock/bureau/BureauStockPage")),
        guard: OfficeGuard
    },

    // this route should be at the end of all other routes
    //{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
    {path: "/", exact: true, component: () => <Redirect to="/login"/>},
];

export {authProtectedRoutes2};
